import React, { createContext, useEffect, useMemo, useState } from "react";
import Cookies from 'js-cookie';
import PropTypes from "prop-types";
import { globalHistory } from "@reach/router";
import * as styles from "@ottomotors/ottomotors-common/styles/disableScroll.module.scss";

export interface IAppContext {
  isBannerVisible: boolean;
  setIsBannerVisible: React.Dispatch<React.SetStateAction<boolean>>;
  isMenuOpen: boolean;
  setIsMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
  windowLocation: Location | undefined;
  lightboxSource: string;
  setLightboxSource: React.Dispatch<React.SetStateAction<string>>;
  isContactModalOpen: boolean;
  setIsContactModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AppContext = createContext<IAppContext>({} as IAppContext);

interface IProps {
  children: React.ReactNode;
}

const AppProvider = ({ children }: IProps) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isBannerVisible, setIsBannerVisible] = useState(false);
  const [windowLocation, setWindowLocation] = useState<Location | undefined>();
  const [lightboxSource, setLightboxSource] = useState("");
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);
  const [contactModalEmbedOverride, setContactModalEmbedOverride] =
    useState(null);

  useEffect(() => {
    setWindowLocation(window.location);

    return globalHistory.listen(({ location }) => {
      setWindowLocation(location);
    });
  }, []);

  // Close menu on page change
  useEffect(() => {
    setIsMenuOpen(false);
  }, [windowLocation]);

  useEffect(() => {
    if (!isContactModalOpen) {
      setContactModalEmbedOverride(null);
    }
  }, [isContactModalOpen]);

  // Disable page scroll
  useEffect(() => {
    if (isContactModalOpen || isMenuOpen || lightboxSource?.length > 0) {
      document.body.classList.add(styles.disableScroll);
    } else {
      document.body.classList.remove(styles.disableScroll);
    }
  }, [isContactModalOpen, isMenuOpen, lightboxSource]);

  const contextProps = useMemo(
    () => ({
      isBannerVisible,
      setIsBannerVisible,
      isMenuOpen,
      setIsMenuOpen,
      lightboxSource,
      setLightboxSource,
      windowLocation,
      isContactModalOpen,
      setIsContactModalOpen,
      contactModalEmbedOverride,
      setContactModalEmbedOverride,
    }),
    [
      isBannerVisible,
      setIsBannerVisible,
      isMenuOpen,
      setIsMenuOpen,
      windowLocation,
      lightboxSource,
      setLightboxSource,
      isContactModalOpen,
      setIsContactModalOpen,
      contactModalEmbedOverride,
      setContactModalEmbedOverride,
    ]
  );

  return (
    <AppContext.Provider value={contextProps}>{children}</AppContext.Provider>
  );
};

AppProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppProvider;
